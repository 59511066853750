@import "../../../shared/colors/colors.module.css";

.text {
  /* using auto instead of 100% so that text does not push everything to the edge when the parent is flex layout ( this happens in case of switch ) */
  width: auto;
  color: inherit;

  @each $color in colors {
    &[data-color="$(color)"] {
      color: var(--color-fg-$(color));
    }
  }

  /* Adding as special case as we can't add neutral-subtle to $colors variable, as that variable is used generically for many components ( button ). */
  &[data-color="neutral-subtle"] {
    color: var(--color-fg-neutral-subtle);
  }

  /* body */
  &[data-variant="body"] {
    letter-spacing: -0.02em;
  }

  /* h6 */
  &[data-variant="caption"][data-weight="500"] {
    letter-spacing: -0.01em;
  }

  /* h5 */
  &[data-variant="body"][data-weight="500"] {
    letter-spacing: -0.02em;
  }

  /* h4 */
  &[data-variant="body"][data-weight="600"] {
    letter-spacing: -0.02em;
  }

  /* h3 */
  &[data-variant="subtitle"][data-weight="600"] {
    letter-spacing: -0.025em;
  }

  /* h2 */
  &[data-variant="title"][data-weight="600"] {
    letter-spacing: -0.03em;
  }

  /* h1 */
  &[data-variant="heading"][data-weight="600"] {
    letter-spacing: -0.04em;
  }
}

.clampedText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
